import React from 'react'
import './style/Footer.css'

const Footer = () => {
  return (
    <div className="row footer-container py-5">
      <div className="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-center">
        <span>Thank you!</span>
        
      </div>
      <div className="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-center">
        <span><a className="footer-text" href="mailto: amandaburtness@gmail.com">amandaburtness@gmail.com</a></span> 
        <span><a className="footer-text" href="https://www.linkedin.com/in/amandaburtness/" target="_blank" rel="noopener noreferrer">linkedin.com/in/amandaburtness</a></span>
      </div>
    </div>
  )
}

export default Footer
