import React from 'react'
import Resume from './Resume'
import './style/About.css'

const About = () => {
  return (
    <div className="row home-container">
      
      <Resume />
    
    </div>
  )
}

export default About
