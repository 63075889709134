import React from 'react'
import { useParams } from 'react-router-dom'
import './style/PortfolioItem.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const PortfolioItem = ({ projectsArray }) => {

    const { url } = useParams()

    const project = projectsArray.find((x) => x.id === url)
  
  return (
    <div className="row portfolio-container pt-0 mt-0">
      <div className='hero-background col-12 px-0 pb-5' style={{backgroundImage: `url(${project.imgDesktop})`}}>
      <div className='gradient'></div>
      </div>
      <div className='pt-5 pb-0 px-md-5 goback'>
        <p className='d-flex justify-content-end'><Link to="/" className="gobackbtn">Go Back</Link></p>
      </div>
      <div className="col-12 col-md-3 pt-5 px-md-5">
        <p>{project.title}</p>
        <p>{project.type}</p>
        <p>{project.date}</p>
      </div>
      <div className="col-12 col-md-9 pt-md-5 px-md-5">
        <p>{project.description}</p>
      </div>

        <div>{project.video ? 
          <div className='p-md-5'>
            <p>Play video below:</p>
            <video controls>
              <source src={ project.video } type="video/mp4"/>
            </video>
          </div>
         : 
         ""
         }<div/>
      </div>

      <div>{project.pdf ? 
        <div className='col-12 pdf p-3'>
          <p className='text-center'><a className='gobackbtn' href={ project.pdf } target="_blank" type="application/pdf" rel="noopener noreferrer">Click here to read!</a></p>
        </div>
        : 
        ""
        }<div/>
      </div>

      <div>{project.poster ? 
        <div className='col-12'>
          <img src={ project.poster } alt="poster" className='img-fluid'/>
        </div>
        : 
        ""
        }<div/>
      </div>
   
   
      <div className='gallery pt-5'>
        { project.imgArr.map((item, index) => {
          return (
            <div className='pics d-flex' key={ index }>
              <img src={ item.img } alt={ item.text }  style={{ width: '100%' }} className='img-fluid'/>
              <div className="hide-item">
                <p>{ item.text }</p>
                <p>{ item.date }</p>
              </div>
            </div>
          )
        }) }
       </div>
       <div className='pt-3 pb-5 px-md-5 goback'>
        <p className='d-flex justify-content-center justify-content-md-end'><Link to="/" className="gobackbtn">Go Back</Link></p>
      </div>
    </div>
  )
}

export default PortfolioItem

