import React, { useState, useEffect, useRef } from 'react';
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import './style/index.css';
import './style/Header.css'
import DragHandleRoundedIcon from '@material-ui/icons/DragHandleRounded';

function Header(args) {

  const [isActive, setActive] = useState(false)
  const toggleClass = () => setActive(!isActive)
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {setIsOpen(!isOpen)};

  const click = () => {
    toggleClass();
    toggle();
 }

 let menuRef = useRef()

 useEffect(() => {
  let handler = (e) => {
    if(!menuRef.current.contains(e.target)){
      setIsOpen(false)
      setActive(false)
    }
  }

  document.addEventListener("mousedown", handler)
 })

  return (
    <div ref={menuRef} className={isActive ? "white-active header-container-1 px-3" : "null header-container-1 px-3"}>
      <Navbar {...args}>
        <NavbarBrand href="/">Amanda Burtness</NavbarBrand>
        {/* <NavbarToggler onClick={toggle} /> */}
        <DragHandleRoundedIcon onClick={click} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink href="/">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/about">About</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Header;






// import React from 'react'
// import './style/index.css';
// import './style/Header.css'
// import { Link } from 'react-router-dom'
// import DragHandleRoundedIcon from '@material-ui/icons/DragHandleRounded';

// const Header = () => {

//   // document.addEventListener('click', () => {
//   //   const header = document.querySelector('.header-container')
//   //   const windowPosition = window.scrollY > 0

//   //   if (window.location.href.includes('about')) {
//   //     header.classList.toggle('active-about')
//   //   } else if (!window.location.href.includes('about')) {
//   //     header.classList.toggle('active')
//   //   }
    
//   // })


//   // const toggleNav = () => {
//   //   const navItem = document.querySelectorAll(".nav-link")
//   //   const header = document.querySelector('.header-container')

//   //   navItem.forEach(item => {
//   //     item.addEventListener("click", () => {
//   //       const togglerButton = document.querySelector(".navbar-toggler")
//   //       const navBar = document.querySelector('.navbar-collapse')
      

//   //       togglerButton.classList.add("collapsed")
//   //       navBar.classList.remove("show")
//   //       header.classList.toggle('active-about')
//   //     })
//   //   })
//   // }

//   return (
//     <div className="row header-container">
//       {/* <nav className="navbar navbar-expand-lg">
//         <div className="container-fluid px-0">
//           <Link to="/" className="navbar-brand">
//             Amanda Burtness
//           </Link>
//           <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//           <DragHandleRoundedIcon />
//           </button>
//           <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
//             <ul className="navbar-nav mb-2 mb-lg-0">
//               <li className="nav-item">
//                 <Link onClick={toggleNav} to="/" className="nav-link py-3" >
//                   Home
//                 </Link>
//               </li>
//               <li className="nav-item">
//                 <Link onClick={toggleNav}  to="/about" className="nav-link py-3">
//                   About
//                 </Link>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav> */}

//       <nav class="navbar navbar-expand-lg navbar-light bg-light">
//         <div class="container-fluid">
//           <a class="navbar-brand" href="#">Navbar</a>
//           <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
//             <span class="navbar-toggler-icon"></span>
//           </button>
//           <div class="collapse navbar-collapse" id="navbarNav">
//             <ul class="navbar-nav">
//               <li class="nav-item">
//                 <a class="nav-link active" aria-current="page" href="#">Home</a>
//               </li>
//               <li class="nav-item">
//                 <a class="nav-link" href="#">Features</a>
//               </li>
//               <li class="nav-item">
//                 <a class="nav-link" href="#">Pricing</a>
//               </li>
//               <li class="nav-item">
//                 <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav>

//     </div>
//   )
// }

// export default Header
