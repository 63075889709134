import React from 'react'
import './style/Resume.css'
import headshot from './images/headshot.jpg'

const Resume = () => {
  return (
    <div className="row resume-container w-100 m-0">
      <div className="col-12 py-5 px-0">
        <div className="col-12 d-flex flex-column flex-lg-row pt-5 mt-5 py-md-5 justify-content-center align-items-center headshot-wrapper mx-auto">
          <img alt="headshot" src={headshot} className="img-fluid headshot" />
          <h1 className="resume-header text-center p-5">I'm Amanda. (:</h1>
        </div>
        <div className="resume-wrapper mx-auto col-12 d-flex flex-wrap px-md-5 py-5">
          <div className="col-12 col-md-2 px-md-5 pt-4 pt-md-0 pb-2">
            <h4>Profile</h4>
          </div>
          <div className="col-12 col-md-10">
            <p className="px-md-5 purple">Passionate about the transformative power of entertainment, I've dedicated my life to merging technical expertise and creative design. With a dual major in Theatre Technology and Audio/Video Design for Live Entertainment, I honed my skills from soldering cables to exploring the emotional nuances of color in art. Professional experiences as an Assistant Technical Director and Lighting Technician have not only equipped me with a robust technical foundation but have instilled in me a deep understanding of the cathartic impact of live performances. Now seeking a master's program, I aspire to broaden my design expertise, specializing in lighting while embracing a comprehensive understanding of scenic, costume, audio, and projection design. Ultimately, my goal is to share my knowledge as a professor, continuing the tradition of inspiring future generations in the theatrical arts.</p>
          </div>

          <div className="col-12 col-md-2 px-md-5 pt-4 pt-md-0 pb-2">
            <h4>Experience</h4>
          </div>
          <div className="col-12 col-md-10">
            <p className="px-md-5 purple">As a versatile professional, I've seamlessly transitioned between roles in the realm of technical entertainment, contributing as a Lighting Designer, Lighting Technician, AV Technician, Assistant Technical Director, Audio Engineer, Video Artist, Programmer, Rigger, Head Carpenter, and Head Video. My diverse experiences, from meticulously crafting lighting designs to managing complex audiovisual setups to coding websites, have cultivated a well-rounded skill set. With a passion for both the technical and artistic aspects of theatre, I am now eager to pursue a master's program to further refine my expertise and contribute to the future of theatrical design.</p>
          </div>

          <div className="col-12 col-md-2 px-md-5 pt-4 pt-md-0 pb-2">
            <h4>Skills</h4>
          </div>
          <div className="col-12 col-md-10">
            <p className="px-md-5 purple">I possess a comprehensive skill set in lighting, video, and audio installation, encompassing proficiency in Hog 4 and ETC programming, Vectorworks, and Adobe software. Additionally, my expertise extends to rigging, carpentry, soldering, and operating counterweight systems, boom lifts, scissor lifts, and forklifts, coupled with a strong foundation in interpreting schematics and holding OSHA 10 certification. Furthermore, my technical versatility includes proficiency in HTML, CSS, Javascript, and React, showcasing my adaptability across various technological domains.</p>
          </div>

          {/* <div className="col-12 col-md-2 px-md-5 pt-4 pt-md-0 pb-2">
            <h4>Tools</h4>
          </div>
          <div className="col-12 col-md-10">
            <p className="px-md-5 purple">With working on an scrum / agile team, I have learned the importance of using progress boards like Jira and Trello, as well as version control software such as Github and Shopify themes. My tool belt also includes working knowledge of how to work on a team of any size, working independently, being able to be a quick learner, and multitasking.</p>
          </div> */}
          <div className="col-12 col-md-2 px-md-5 pt-4 pt-md-0 pb-2">
            <h4>Resume</h4>
          </div>
          <div className="col-12 col-md-10">
            <p className="px-md-5 purple"><a target="_blank" href="/images/AmandaBurtnessResumeUpdatedWeb.pdf">click to open in new tab</a></p>
          </div>
          <div className="col-12 col-md-2 px-md-5 pt-4 pt-md-0 pb-2">
            <h4>Contact</h4>
          </div>
          <div className="col-12 col-md-10">
            <p className="px-md-5 purple">Phone: <a href="sms:7076310376">(707) 631-0376</a></p>
            <p className="px-md-5 purple">Email: <a href="mailto: amandaburtness@gmail.com">amandaburtness@gmail.com</a></p>
            <p className="px-md-5 purple">LinkedIn: <a href="https://www.linkedin.com/in/amandaburtness/" target="_blank" rel="noopener noreferrer">linkedin.com/in/amandaburtness</a></p>
            <p className="px-md-5 purple">Instagram: <a href="https://www.instagram.com/amandaplayswithlight/?hl=en" target="_blank" rel="noopener noreferrer">instagram.com/amandaplayswithlight</a></p>
            {/* <p className="px-md-5 purple">GitHub: <a href="https://github.com/amandaburtness">github.com/amandaburtness</a></p> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resume
