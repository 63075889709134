import React from 'react';
import './style/index.css';
import './style/Home.css'
import PortfolioBlock from './PortfolioBlock';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Home = ({ projectsArray }) => {

  return (
    <div className="row home-container">
      {/* Hero */}
      <div className="row home-wrapper mx-auto d-flex align-items-center">
        <div className="pt-5 px-lg-5 mx-lg-5">
          <h1 className="px-lg-5 mx-lg-5">Hi! I'm Amanda Burtness, a dedicated Lighting Designer and Artist passionately committed to crafting designs that leave a lasting impact and convey profound meaning.</h1>
          <p className="py-5 px-lg-5 mx-lg-5 purple">Scroll down to see my work!</p>
        </div>
      </div>

      

      {/* Projects */}
      <div className="row px-0 mx-0">
        {projectsArray.map((project) => (
          <PortfolioBlock key={project.id} id={project.id} title={project.title} date={project.date} type={project.type} description={project.description} imgDesktop={project.imgDesktop} imgMobile={project.imgMobile} imgArr={project.imgArr}/>
       ))}
       <div className='col-12 col-md-6 more-about p-5 d-flex align-items-center'>
          <Link to="/about"><h1>Click here to learn more about me! (:</h1></Link>
       </div>
      </div>
    </div>
  )
}

export default Home;