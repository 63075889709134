import React from 'react'
import './style/PortfolioBlock.css'
import { Link } from 'react-router-dom'

const PortfolioBlock = ({ id, title, date, type, description, imgDesktop, imgMobile}) => {

  return (
    <div className="col-md-6 col-12 project-container p-0 m-0" id="portfolioblock">
        <Link to={`/portfolio/${id}` } >
          <img src={imgDesktop} alt={title} className="about d-block img-fluid project-image mx-auto" />
          <h2 className="hide">{title}</h2>
        </Link>
    </div>
  )
}

export default PortfolioBlock
