// import './App.css';
import React from 'react';
import Header from './Header.js';
import Home from './Home.js'
import About from './About.js';
import PortfolioItem from './PortfolioItem.js';
import Scroll from './Scroll.js';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Footer from './Footer.js';

function App() {


  const projectsArray = [
    {
      id: "1",
      title: "Architectual Lighting Design",
      date: "June 20, 2023 - Present",
      type: "Lighitng Design",
      description: "As part of my role at the Dallas Symphony Orchestra, I oversee the architectural lighting design for special events in the Meyerson Symphony Center. Faced with the constraint of utilizing the equipment available in our venue inventory, I embrace the challenge of creating captivating atmospheres for patrons with a limited selection of gear. From the vibrant celebration of Dia de los Muertos to romantic weddings, I specialize in subtly immersing patrons into the unique ambiance of each event hosted at the Dallas Symphony Orchestra.",
      imgDesktop: "/images/arch2.jpg",
      imgMobile: "/images/arch2.jpg",
      imgArr: [ 
        {
          img: "/images/arch2.jpg", 
          text: "Dallas Architecture Forum 25th Anniversary Event",
          date: "10/11/23"
        }, 
        {
            img: "/images/arch1.jpg", 
            text: "Dallas Architecture Forum 25th Anniversary Event",
            date: "10/11/23"
        }, 
        {
            img: "/images/arch3.jpg", 
            text: "Dallas Architecture Forum 25th Anniversary Event",
            date: "10/11/23"
        }, 
        {
            img: "/images/arch4.jpeg", 
            text: "Dallas Symphony Orchestra Speakeasy Night (ALD)",
            date: "09/09/23"
        }, 
        {
            img: "/images/arch5.jpeg", 
            text: "Dallas Symphony Orchestra Speakeasy Night (ALD)",
            date: "09/09/23"
        }, 
        {
            img: "/images/arch6.jpeg", 
            text: "Dallas Symphony Orchestra Speakeasy Night (ALD)",
            date: "09/09/23"
        }, 
        {
            img: "/images/arch7.jpeg", 
            text: "Dallas Symphony Orchestra Speakeasy Night (ALD)",
            date: "09/09/23"
        }, 
        {
            img: "/images/arch8.jpeg", 
            text: "Dallas Symphony Orchestra Speakeasy Night (ALD)",
            date: "09/09/23"
        }, 
        {
            img: "/images/arch9.jpeg", 
            text: "Dallas Architecture Forum 25th Anniversary Event",
            date: "10/11/23"
        }, 
        {
            img: "/images/arch12.jpeg", 
            text: "Dallas Architecture Forum 25th Anniversary Event",
            date: "10/11/23"
        }, 
        {
            img: "/images/arch13.jpeg", 
            text: "Dallas Symphony Orchestra Fashion Notes Luncheon",
            date: "10/12/23"
        }, 
        {
            img: "/images/arch14.jpeg", 
            text: "North Dallas Bank & Trust Co. Reception",
            date: "10/19/23"
        }, 
        {
            img: "/images/arch16.jpeg", 
            text: "DSO Dia de los Muertos '23",
            date: "10/31/23"
        }, 
        {
            img: "/images/arch17.jpeg", 
            text: "DSO Dia de los Muertos '23",
            date: "10/31/23"
        }, 
        {
            img: "/images/arch18.jpeg", 
            text: "DSO Dia de los Muertos '23",
            date: "10/31/23"
        }, 
        {
            img: "/images/arch19.jpeg", 
            text: "DSO Veteran's Day '23",
            date: "11/11/23"
        }, 
        {
            img: "/images/arch20.jpeg", 
            text: "DSO Veteran's Day '23",
            date: "11/11/23"
        }, 
        {
            img: "/images/arch21.jpeg", 
            text: "DSO Veteran's Day '23",
            date: "11/11/23"
        }, 
        {
            img: "/images/arch23.JPG", 
            text: "DSO Happy Holidays '23",
            date: "December 2023"
        }]
    },
    {
      id: "2",
      title: "Exploration of Womanhood in Video: An Immersive Art Piece",
      date: "April 2019",
      type: "Immersive Video Art",
      description: "My interactive video installation art piece demonstrates the exploration of the intricate relationship between women's bodies and the world of film and media. Viewers are immediately enveloped in an otherworldly ambiance, as they step into this immersive environment, becoming an integral part of the piece. Without them, the piece would not, and could not, exist. Their presence triggers a captivating interplay of colors, with the primary hues of red, green, and blue dominating the visual palette. These colors are emblematic of the fundamental building blocks of digital imagery, and in my work, they serve as both a nod to the technical origins of media and a tool for expression.",
      imgDesktop: "/images/vid1.jpeg",
      imgMobile: "/images/vid1.jpeg",
      imgArr: [ 
        {
            img: "/images/vid1.jpeg", 
            text: "Image taken during viewing",
            date: "April 2019"
        }, 
        {
            img: "/images/vid2.jpeg", 
            text: "Image taken during viewing",
            date: "April 2019"
        }, 
        {
            img: "/images/vid3.jpeg", 
            text: "Image taken during viewing",
            date: "April 2019"
        }, 
        {
            img: "/images/vid4.jpeg", 
            text: "Image taken during viewing",
            date: "April 2019"
        }, 
        {
            img: "/images/vid5.jpeg", 
            text: "Image taken during viewing (featuring Clause the dog)",
            date: "April 2019"
        }, 
        {
            img: "/images/vid6.jpeg", 
            text: "Image taken during viewing",
            date: "April 2019"
        }, 
        {
            img: "/images/vid7.jpeg", 
            text: "Image taken during viewing",
            date: "April 2019"
        }, 
        {
            img: "/images/vid8.jpeg", 
            text: "Image taken during viewing",
            date: "April 2019"
        }, 
        {
            img: "/images/vid9.jpeg", 
            text: "Image taken during viewing",
            date: "April 2019"
        }]
    },
    {
      id: "3",
      title: "Dallas Symphony Orchestra Lighting Design",
      date: "June 20, 2023 - Present",
      type: "Lighitng Design",
      description: "As the primary Lighting Designer at the Dallas Symphony Orchestra, I specialize in crafting dynamic lighting setups for third-party events and guest artists. Working within tight timeframes, I leverage the house plot to create customized designs that meet the specific preferences of each visiting artist. This role demands a balance of artistic flair and technical precision, ensuring a visually captivating experience for diverse performances.",
      imgDesktop: "/images/lxdes1.jpeg",
      imgMobile: "/images/lxdes1.jpeg",
      imgArr: [ 
        {
            img: "/images/lxdes1.jpeg", 
            text: "Kogan Recital - NPR",
            date: "10/15/23"
        }, 
        {
            img: "/images/lxdes2.jpeg", 
            text: "Dallas Winds - Vital Sines",
            date: "11/07/23"
        }, 
        {
            img: "/images/lxdes3.jpeg", 
            text: "Kogan Recital - NPR",
            date: "10/15/23"
        }, 
        {
            img: "/images/lxdes4.jpeg", 
            text: "Kogan Recital - NPR",
            date: "10/15/23"
        }, 
        {
            img: "/images/lxdes5.jpeg", 
            text: "Dallas Winds - Vital Sines",
            date: "11/07/23"
        }, 
        {
            img: "/images/lxdes7.jpeg", 
            text: "Kogan Recital - NPR",
            date: "10/15/23"
        }, 
        {
          img: "/images/lxdes10.jpeg", 
          text: "Dallas Winds - Vital Sines",
          date: "11/07/23"
        }, 
        {
            img: "/images/lxdes11.jpeg", 
            text: "Dallas Winds - Vital Sines",
            date: "11/07/23"
        }, 
        {
            img: "/images/lxdes12.jpeg", 
            text: "Dallas Architecture Forum 25th Anniversary Event",
            date: "10/11/23"
        }, 
        {
            img: "/images/lxdes13.jpeg", 
            text: "Debutante Presentation",
            date: "09/22/23"
        }, 
        {
            img: "/images/lxdes16.jpeg", 
            text: "Millennial® Choirs & Orchestras (MCO®), ALD and programmer to Jacob Hughes" ,
            date: "12/04/23"
        }, 
        {
            img: "/images/lxdes17.jpeg", 
            text: "Millennial® Choirs & Orchestras (MCO®), ALD and programmer to Jacob Hughes" ,
            date: "12/04/23"
        }, 
        {
            img: "/images/lxdes18.jpeg", 
            text: "Millennial® Choirs & Orchestras (MCO®), ALD and programmer to Jacob Hughes" ,
            date: "12/04/23"
        }, 
        {
            img: "/images/lxdes19.jpeg", 
            text: "Millennial® Choirs & Orchestras (MCO®), ALD and programmer to Jacob Hughes" ,
            date: "12/04/23"
        }, 
        {
            img: "/images/lxdes20.jpeg", 
            text: "Millennial® Choirs & Orchestras (MCO®), ALD and programmer to Jacob Hughes" ,
            date: "12/04/23"
        }]
    },
    {
      id: "4",
      title: "The Perfect Woman (Video Art)",
      date: "May 2019",
      type: "Video Art",
      video: "/images/videofem.mp4",
      description: "In response to Jorgen Leth's 'The Perfect Human' (1967), I created a feminist reinterpretation using a similar film noir documentary style. I focused solely on the female body, challenging traditional notions of the 'perfect female'. Employing Leth's stylistic choices such as evocative lighting, dutch angles, and close-ups, I portrayed the female character in a way that deconstructs and sasses the male gaze. The video critiques the portrayal of women as docile and solely existing for male pleasure, highlighting the absurdity of such notions through the character's dismissive expressions. This work aims to challenge and question persisting ideologies that objectify women for male consumption.",
      imgDesktop: "/images/fem9.png",
      imgMobile: "/images/fem9.png",
      imgArr: [ 
        {
            img: "/images/fem1.png", 
            text: "Still from video",
            date: "May 2019"
        }, 
        {
            img: "/images/fem2.png", 
            text: "Still from video",
            date: "May 2019"
        }, 
        {
            img: "/images/fem3.png", 
            text: "Still from video",
            date: "May 2019"
        }, 
        {
            img: "/images/fem4.png", 
            text: "Still from video",
            date: "May 2019"
        }, 
        {
            img: "/images/fem5.png", 
            text: "Still from video",
            date: "May 2019"
        }, 
        {
            img: "/images/fem6.png", 
            text: "Still from video",
            date: "May 2019"
        },
        {
            img: "/images/fem8.png", 
            text: "Still from video",
            date: "May 2019"
        }, 
        {
            img: "/images/fem9.png", 
            text: "Still from video",
            date: "May 2019"
        }, 
        {
            img: "/images/fem14.png", 
            text: "Still from video",
            date: "May 2019"
        }, 
        {
            img: "/images/fem10.png", 
            text: "Still from video",
            date: "May 2019"
        }, 
        {
            img: "/images/fem11.png", 
            text: "Still from video",
            date: "May 2019"
        }, 
        {
            img: "/images/fem13.png", 
            text: "Still from video",
            date: "May 2019"
        },
        {
            img: "/images/fem15.png", 
            text: "Still from video",
            date: "May 2019"
        }]
    },
    {
      id: "5",
      title: "A Study of Intimacy",
      date: "2016-2020",
      type: "Photography, Film 35mm, 50mm Lens",
      description: "During my time at Chapman University, I delved into the exploration of intimacy and humanity within my social circle and the wider world. Armed with a 35mm film camera and a 50mm lens, I opted for a hands-on approach, physically positioning myself closer to subjects to capture moments. This not only allowed me to experiment with light and texture but also provided a unique perspective on the connections and nuances of human relationships.",
      imgDesktop: "/images/photography_4.jpg",
      imgMobile: "/images/photography_4.jpg",
      imgArr: [ 
        {
          img: "/images/photography_3.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_4.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_5.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_6.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_7.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_8.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_9.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_10.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_11.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_12.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_13.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_14.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_15.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_16.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_17.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_18.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_19.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_20.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_21.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_23.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_24.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_25.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_26.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_27.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_28.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_29.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_31.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_32.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_33.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_34.jpg", 
          text: "Print",
          date: ""
        },
        {
          img: "/images/photography_36.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_37.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_39.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_41.jpg", 
          text: "Print",
          date: ""
        }, 
        {
          img: "/images/photography_42.jpg", 
          text: "Print",
          date: ""
        }]
    },
    {
      id: "6",
      title: "Haunting Immersion: A Biological and Technical Need",
      date: "November 2018",
      type: "Undergraduate Thesis",
      description: "In my thesis, I discuss the need for haunts as a form of technical and performance entertainment, as well as a need of immersive storytelling. I also show the way the human body and mind reacts to fear, and how this is purposeful in the means of immersive storytelling. This form of immersive theatre is on the rise and pertinent for both patrons and the entertainment industry. There is a human need to experience adrenaline and fear. There is a human need to tell stories. There is a human need to create. Haunts serve all of these purposes and are able to adapt to changes in human fear and desires over time. They not only serve as a means of entertainment and excitement, but they are able to tell a story in a way that a straight play would not. They are a form of immersive theatre, where the audience can live out their wildest nightmares and step into a world only dreamt up in the dark corners of their minds.",
      imgDesktop: "/images/thesisimg.png",
      imgMobile: "/images/thesisimg.png",
      poster: "/images/thesisposter.png",
      pdf: "/images/thesispaper.pdf",
      imgArr: [ 
        {
          img: "/images/thesisimg.png", 
          text: "Close up of poster image",
          date: "November 2018"
        }, 
        {
          img: "/images/thesisimg2.png", 
          text: "Close up of poster image",
          date: "November 2018"
        }, 
        {
          img: "/images/thesisimg3.png", 
          text: "Close up of poster image",
          date: "November 2018"
        }]
    },
    {
      id: "7",
      title: "Portfolio Website",
      date: "November 2023",
      type: "Web Development UX/UI",
      description: "In anticipation of school applications and a comprehensive portfolio update, I undertook the initiative to redesign and personally code my website (this website!). This endeavor aimed to seamlessly integrate my background in entertainment and art with my coding proficiency. Employing a React framework, the website serves as a dynamic representation of my multifaceted skills. Currently hosted on Google Domains, it not only showcases my artistic endeavors but also reflects my commitment to a cohesive and technologically proficient digital presence.",
      imgDesktop: "/images/code.jpeg",
      imgMobile: "/images/code.jpeg",
      imgArr: [ 
        {
          img: "/images/htmlcss.png", 
          text: "HTML5, CSS, Javascript6",
          date: ""
        }, 
        {
          img: "/images/react.png", 
          text: "React",
          date: ""
        }, 
        {
          img: "/images/bootstrap.jpeg", 
          text: "Bootstrap5",
          date: ""
        }]
    }
  ]

  return (
    
    <Router>
      <Scroll>
      <div className="App container-fluid">

        <Header />

        <Switch>
          <Route path="/portfolio/:url">
            <PortfolioItem projectsArray={projectsArray} />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route index path="/">
            <Home projectsArray={projectsArray} />
          </Route>
        </Switch>

        <Footer />
        
      </div>
      </Scroll>
    </Router>
    
  );
}

export default App;
